import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  EventBySlugQuery,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import ContentfulRichText from './contentfulRichText';
import { Header1, Header3 } from './headers';
import Layout from './layout';
import SEO from './seo';
import LinkComponent from './linkComponent';
import {
  Eventbrite,
  Facebook,
  // Twitter,
  Zoom,
} from '../assets/vectors/socialIcons';

type Props = {
  pageContext: {
    title: string;
    slug: string;
  };
  data: EventBySlugQuery;
};

const EventPage: React.FC<Props> = ({ pageContext, data }: Props) => {
  const date = data?.contentfulEvent?.date;
  const facebookEventLink = data?.contentfulEvent?.facebookEventLink;
  const eventbriteLink = data?.contentfulEvent?.eventbriteLink;
  const zoomRegistrationLink = data?.contentfulEvent?.zoomRegistrationLink;

  const photoDesktop = data?.contentfulEvent?.photoDesktop;
  const photoMobile = data?.contentfulEvent?.photoMobile;

  return (
    <Layout pathname={`/blog/${pageContext.slug}`}>
      <SEO
        title={pageContext.title}
        shareImage={
          photoDesktop?.gatsbyImageData?.images?.fallback?.src
            ? `https:${photoDesktop.gatsbyImageData.images.fallback.src}`
            : undefined
        }
      />
      <div className="container mx-auto md:flex pt-12">
        <div className="w-1/4 pt-2 pr-10 hidden md:flex">
          <div>
            <Header3 className="pb-1 pl-4 uppercase text-black hover:text-usm-dark-red">
              {date}
            </Header3>
            <div className="flex pl-4 mt-2">
              {facebookEventLink && (
                <LinkComponent link={facebookEventLink} className="mr-3">
                  <Facebook />
                </LinkComponent>
              )}
              {eventbriteLink && (
                <LinkComponent link={eventbriteLink} className="mr-3">
                  <Eventbrite />
                </LinkComponent>
              )}
              {zoomRegistrationLink && (
                <LinkComponent link={zoomRegistrationLink} className="mr-3">
                  <Zoom />
                </LinkComponent>
              )}
            </div>
          </div>
        </div>
        <div className="md:flex md:flex-1">
          <div className="md:max-w-screen-sm">
            {photoMobile && (
              <div className="md:hidden my-5">
                <GatsbyImage
                  alt="TODO: add alt tag"
                  image={photoMobile.gatsbyImageData}
                />
              </div>
            )}
            <b className="text-white inline-block md:hidden mx-5">{date}</b>
            <Header1
              tag="h1"
              className="mb-4 mx-5 md:mx-0"
              color="black"
              mobileColorsFlipped
            >
              {pageContext.title}
            </Header1>
            {photoDesktop && (
              <div className="hidden md:block flex max-w-screen-sm">
                <GatsbyImage
                  alt="TODO: add alt tag"
                  image={photoDesktop.gatsbyImageData}
                />
              </div>
            )}
            <div className="blog-post-content text-base text-white md:text-black">
              <ContentfulRichText
                document={data?.contentfulEvent?.description}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      description {
        raw
      }
      photoDesktop: photo {
        gatsbyImageData(layout: FIXED, width: 640)
      }
      photoMobile: photo {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.75
          width: 700
          cropFocus: CENTER
          placeholder: BLURRED
        )
      }
      date(formatString: "MMMM Do, YYYY")
      facebookEventLink
      eventbriteLink
      zoomRegistrationLink
    }
    contentfulMenu(menuId: { eq: "main" }) {
      items {
        id
        text
        link
        appearsOnHomepage
        subMenu {
          id
          text
          link
          appearsOnHomepage
        }
      }
      ctaItem {
        text
        link
      }
      secondaryCtaItem {
        text
        link
      }
    }
  }
`;

export default EventPage;
